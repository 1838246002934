import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RandomImage from "./images/AfterWork.jpg"
import * as ProjectStyle from './css/contact.module.scss'

import Zoom from 'react-medium-image-zoom'

import 'react-medium-image-zoom/dist/styles.css'

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Talk to Nicolas Julian" />
      <div className={ProjectStyle}>
      <h1>Talk to Me</h1>
      <p>Untuk menghubungi saya, silahkan kirimkan email ke <b><a href="mailto:me@nicolas.my.id">me@nicolas.my.id</a></b>. Email tersebut saya gunakan untuk menerima semua email yang ditujukan khusus saya pribadi.</p>
      <p>Here’s a picture of my after work vibes, <b>Sidewalk MRT Bendungan Hilir</b></p>
      <ul>
        <div>
          <center>
            <Zoom>
              <img src={RandomImage} className={ProjectStyle.gambar} alt="Sidewalk MRT Bendungan Hilir - by Nicolas Julian"/>
            </Zoom>
          </center>
          </div>
        </ul>
      </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
